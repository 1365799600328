.App {
  text-align: left;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Outfit', sans-serif; /* Ensure Outfit font is imported */
}

.App-header {
  padding: 2rem; /* Or adjust to match the design */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-logo {
  height: 76px; /* Adjust based on design specs */
}

.App-content {
  padding: 1rem; /* Adjust to match design */
  text-align: left; /* Align text to the left */
}

.cognitive-button {
  background-color: #61dafb;
  border: none;
  padding: 0.5rem 1rem; /* Adjust to match design */
  font-size: 1rem;
  border-radius: 20px;
  margin: 1rem 0; /* Adjust margin to match design */
}


.App-main h1 {
  font-size: 57px; /* Adjust base font-size */
  font-weight: 500;
  line-height: 1.1; /* Use a unitless line-height for better scaling */
  letter-spacing: -0.015em;
  margin: 0 0 1rem; /* Adjust margins as needed */
  text-align: left;
}

.App-main p {
  font-size: 24px; /* Adjust base font-size */
  font-weight: 400;
  line-height: 1.2; /* Use a unitless line-height for better scaling */
  letter-spacing: -0.015em;
  margin: 0 0 1rem; /* Adjust margins as needed */
  color: #B9B1C9;
  text-align: left;
}


.email-form {
  display: flex;
  align-items: center; /* This will vertically align the input and button */
  gap: 10px; /* Adjust the space between the elements */
}

.email-input {
  background-color: #232128; /* Background color of the input box */
  color: white; /* Color of the text inside the input box */
  border: 1px solid #4A4553; /* Border color and width */
  padding: 10px 20px; /* Adjust padding as necessary */
  font-size: 16px; /* Font size inside the input box */
  border-radius: 4px 0 0 4px; /* Rounded corners on the left side */
  height: 50px; /* Height of the input box */
  width: auto; /* Adjust the width as necessary, or use 'auto' to fit content */
  max-width: 280px; /* Use max-width if you want the input to grow smaller than 280px when the screen size is reduced */
  flex-grow: 0; /* Do not allow the input to grow to fill available space; this will respect the width/max-width set */
  margin-right: -1px; /* Overlaps the border with the button to avoid double borders */
}

/* To ensure the placeholder text is also white */
.email-input::placeholder {
  color: white; /* Color of the placeholder text */
  opacity: 1; /* Make sure it's fully opaque */
}

.notify-button {
  background-color: #21F2FF;
  color: #282c34;
  border: none;
  padding: 10px 20px; /* Adjust padding to center the text vertically if needed */
  font-size: 16px; /* Should match the input text size */
  border-radius: 0 4px 4px 0; /* Rounded corners on the right side only */
  height: 52px; /* Make sure this matches the input height for alignment */
  box-shadow: none; /* Remove box-shadow if it's not in your design */
}

/* Style adjustments when hovered */
.notify-button:hover {
  background-color: #39D2F7; /* Or whatever hover color you prefer */
}

/* Existing styles */

/* Responsive design adjustments */
@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
    align-items: center; /* Center header items */
    padding: 1rem; /* Add padding to ensure content does not touch the edges */
  }

  .App-logo,
  .App-content,
  .App-main h1 {
    font-size: 32px; /* Smaller font size for mobile */
    line-height: 1.2; /* Adjusted line-height for mobile */
    padding: 0 1rem; /* Add padding on the sides */
  }

  .App-main p {
    font-size: 18px; /* Smaller font size for mobile */
    line-height: 1.3; /* Adjusted line-height for mobile */
    padding: 0 1rem; /* Add padding on the sides */
  }

  .email-form {
    flex-direction: column;
    width: calc(100% - 2rem); /* Reduce width to allow for padding */
    margin: 0 1rem; /* Add horizontal margin to the form */
  }

  .email-input,
  .notify-button {
    width: auto; /* Allow the width to be determined by padding and content */
    max-width: calc(100% - 2rem); /* Ensure max-width allows for padding */
    margin-bottom: 0.5rem; /* Add some space below the input */
  }

  .notify-button {
    width: auto; /* Allow the width to adjust based on content and padding */
    margin-bottom: 0; /* Remove the bottom margin if not needed */
  }
}
